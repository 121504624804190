<template>
  <div
    class="genre-mapping-v2 mb-8 d-flex justify-content-start flex-row"
    id="genre_mapping_v2"
    v-loading.fullscreen.lock="loading"
  >
    <div class="genre-mapping-left-bar me-10">
      <div class="shadow-card card genre-mapping-card">
        <div class="card-body">
          <div class="card-head">
            <span class="heading-sideBar"> Genres map</span>
            <hr />
          </div>
          <div class="search-bar">
            <input
              class="form-control form-control-solid me-3 search-field"
              placeholder="Search genre"
              v-model="search"
            />
            <svg
              class="search-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
              />
            </svg>
          </div>
          <div class="sidebar-genre-list mt-2">
            <ul class="list-group">
              <li
                class="list-group-item mb-4 pb-4"
                v-for="(genre, i) in filteredGenres"
                :key="i"
                @click="openGenreBlock(genre)"
              >
                <div class="d-flex align-items-center">
                  <div class="sub-headings-sidebar">{{ genre.genre }}</div>
                  <div
                    class="ms-auto"
                    style="color: #33647f !important; font-size: 10px"
                  >
                    {{ genre.subText }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="genre-mapping-main-content w-100">
      <div class="shadow-card card">
        <div class="genres-map-wrapper">
          <div
            class="d-flex flex-wrap"
            style="height: 770.5px; width: 100%; flex-direction: column"
          >
            <div
              v-for="(genre, i) in genres"
              :key="i"
              @click="changePosition(i, genre)"
              :style="{
                width: `25%`,
              }"
            >
              <div
                :class="[
                  'm-2 genre-block',
                  genre.genre === currentlyOpenGenre ? 'set-height' : '',
                ]"
                :style="{
                  'background-color': `${genre.colour}`,
                }"
              >
                <div class="title" v-if="genre.genre !== currentlyOpenGenre">
                  <h4>
                    {{ capitalizeEveryWordFirstLetter(genre.genre) }}
                  </h4>
                </div>
                <ul
                  class="genre-block-list"
                  v-if="genre.genre === currentlyOpenGenre"
                >
                  <div class="title">
                    <h4>
                      <a class="main-genre">{{
                        capitalizeEveryWordFirstLetter(genre.genre)
                      }}</a>
                    </h4>
                  </div>
                  <li
                    class="item"
                    v-for="(subGenre, index) in genre.subGenres"
                    :key="index"
                  >
                    <a class="sub-genre-item" href="#">{{
                      subGenre.subGenre
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import { getGenreHierarchy } from "@/api/genreResult";
import { capitalizeEveryWordFirstLetter } from "@/utils/staticHelper";

export default {
  name: "GenreMappingV2",
  setup() {
    const loading = ref(false);
    const currentlyOpenGenre = ref(null);
    const genres = ref([]);
    const colours = ref([
      "#66d8ed",
      "#66c2e7",
      "#798cc2",
      "#a281bd",
      "#66c9ab",
      "#7baad5",
      "#66cd94",
      "#66d2d6",
      "#fdd471",
      "#c9e188",
      "#ffe566",
      "#f5907a",
      "#e0e97f",
      "#a6d78f",
      "#fabc78",
    ]);
    const conditionalIndexes = ref([2, 3, 6, 7, 10, 11, 14, 15]);
    const sideBarGenreList = ref([]);
    const search = ref(null);
    const filteredGenres = ref([]);
    onMounted(() => {
      getGenreDetails();
    });
    const getGenreDetails = async () => {
      try {
        loading.value = true;
        let { data } = await getGenreHierarchy();
        genres.value = data;
        if (genres.value.length > 0) {
          currentlyOpenGenre.value = genres.value[0].genre;
          for (const genre of genres.value) {
            genre["colour"] = colours.value[genres.value.indexOf(genre)];
            sideBarGenreList.value.push({
              genre: capitalizeEveryWordFirstLetter(genre.genre),
              subText: genre.subGenres.length + " subgenres",
              parentGenreName: genre.genre,
              color: genre["colour"],
            });
            if (genre.subGenres.length > 0) {
              for (const subGenre of genre.subGenres) {
                sideBarGenreList.value.push({
                  genre: capitalizeEveryWordFirstLetter(subGenre.subGenre),
                  subText: capitalizeEveryWordFirstLetter(genre.genre),
                  parentGenreName: genre.genre,
                });
              }
            }
          }
          filteredList();
        }
        data = [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    const filteredList = (value) => {
      if (value) {
        filteredGenres.value = sideBarGenreList.value.filter((genre) =>
          genre.genre.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        filteredGenres.value = sideBarGenreList.value;
      }
    };
    const openGenreBlock = (object) => {
      const findGenre = genres.value.filter(function (genre) {
        if (genre.genre === object.parentGenreName) return genre;
      });
      if (findGenre.length > 0) {
        const index = genres.value.indexOf(findGenre[0]);
        changePosition(index, findGenre[0]);
      }
    };
    const insert = (arr, index, newItem) => [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index),
    ];
    const changePosition = (i, genre) => {
      currentlyOpenGenre.value = genre.genre;
      let slicedArray = [];
      if (conditionalIndexes.value.includes(i)) {
        slicedArray = genres.value.splice(i + 1, 2); // start index and items to delete
        let newArray = [];
        if (slicedArray.length === 2) {
          for (const element of slicedArray) {
            newArray = insert(genres.value, i, element);
            genres.value = newArray;
          }
        }
      }
    };

    watch(
      () => search.value,
      (value) => {
        filteredList(value);
      }
    );
    return {
      loading,
      genres,
      search,
      filteredGenres,
      colours,
      currentlyOpenGenre,
      openGenreBlock,
      changePosition,
      capitalizeEveryWordFirstLetter,
    };
  },
};
</script>
<style lang="scss">
.genre-mapping-left-bar {
  .genre-mapping-card {
    width: 300px;
    transition: 0.6s;

    .card-body {
      padding: 1rem !important;

      input::placeholder {
        color: #33647f !important;
        font-size: 12px;
        font-weight: 400;
      }

      .search-bar {
        position: relative;
        width: -webkit-fill-available;
        padding-bottom: 1.5rem !important;
      }

      .search-field {
        padding: 0.75rem 1.5rem !important;
        line-height: 0 !important;
      }

      .search-icon {
        position: absolute;
        right: 4%;
        font-size: 24px;
        bottom: 50%;
        fill: #33647f;
      }

      .sidebar-genre-list {
        height: 639px;
        overflow: hidden;
        overflow-y: auto;

        .list-group-item {
          cursor: pointer;
          border: none;
          padding: 0 2px;
          border-bottom: 1px solid #dee4ee;
        }
      }
    }
  }
}

.genre-mapping-main-content {
  .shadow-card {
    .genres-map-wrapper {
      padding: 16px;
      border-radius: 8px;

      .genre-block {
        transition: all 0.9ms ease-in-out;
        height: 179px;
        padding: 20px;

        a {
          color: #000;
        }

        .main-genre:hover {
          text-decoration: underline !important;
        }

        .genre-block-list {
          padding-top: 8px;
          padding-left: 2px;

          .item {
            padding: 3px 0;
            font-size: 11px;

            .sub-genre-item:hover {
              text-decoration: underline !important;
            }
          }
        }
      }
    }
  }
}

ul li {
  list-style-type: none;
}

.set-height {
  height: 371px !important;
  transition: all 0.5s linear !important;
  box-shadow: 0 4px 10px 2px rgba(31, 31, 31, 0.5) !important;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
